@import './variables.scss';

body {
  background-color: black;
  color: white;
  overflow-y: clip;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

